import React from 'react';
import './CardBio.scss';

/**
 * @namespace CardBio
 * @function CardBio
 * @author Ryan Groom
 * @since 07/11/22
 * @version 1.0.0
 * @component
 */
export default function CardBio({ imageSrc, name, phone, email }) {
  return (
    <div
      className='Card-Bio'
      style={{
        backgroundImage: `url('${imageSrc}')`,
      }}
    >
      <div className='Contact-Info'>
        <span className='h3'>{name}</span>
        {phone && <a href={`tel:${phone}`}>{phone}</a>}
        {email && <a href={`mailto:${email}`}>{email}</a>}
        <svg
          fill='currentColor'
          width='700pt'
          height='700pt'
          version='1.1'
          viewBox='0 0 700 700'
          xmlns='http://www.w3.org/2000/svg'
        >
          <g fill-rule='evenodd'>
            <path d='m550 280c0-110.46-89.543-200-200-200s-200 89.543-200 200 89.543 200 200 200 200-89.543 200-200zm40 0c0-132.55-107.45-240-240-240s-240 107.45-240 240 107.45 240 240 240 240-107.45 240-240z' />
            <path d='m447.46 280c0 11.047-8.9531 20-20 20h-170.25c-11.047 0-20-8.9531-20-20s8.9531-20 20-20h170.25c11.047 0 20 8.9531 20 20z' />
            <path d='m452.68 261.51c9.7422 10.715 9.707 27.086-0.078125 37.762l-74.832 81.613c-7.4648 8.1406-20.113 8.6875-28.258 1.2227-8.1406-7.4648-8.6875-20.113-1.2227-28.258l67.426-73.535-67.484-74.223c-7.4297-8.1758-6.8281-20.824 1.3438-28.254 8.1719-7.4297 20.82-6.8281 28.254 1.3438z' />
          </g>
        </svg>
      </div>
    </div>
  );
}
