import * as React from 'react';
import Banner from '../../Components/Banner/Banner';
import Layout from '../../Components/Layout/Layout';
import CardBio from '../../Components/CardBio/CardBio';
import { PortableText } from '@portabletext/react';

import { graphql, useStaticQuery } from 'gatsby';

import './styles.scss';

// markup
const CoachesPage = () => {
  const data = useStaticQuery(graphql`
    query coachesQuery {
      allSanityCoaches(sort: { fields: orderRank }) {
        nodes {
          name
          phone
          email
          _rawAbout
          picture {
            alt
            image {
              hotspot {
                y
                x
              }
              crop {
                top
                right
                left
                bottom
              }
              asset {
                url
              }
            }
          }
        }
      }
    }
  `);
  const coaches = data?.allSanityCoaches?.nodes;
  return (
    <Layout className='Coaches'>
      <Banner title='Coaches' />
      <section className='Background-White Coach-Section'>
        <div className='Frame-Inner Grid Grid-L Grid-1 Grid-Gap-4'>
          <h2>Meet The Coaches</h2>
          {coaches.map((coach, index) => (
            <div
              className={`Grid Grid-L Grid-1-1 Grid-Gap-4 Grid-Center Grid-Middle ${
                index % 2 ? 'Grid-Reverse' : ''
              }`}
            >
              <div>
                <CardBio
                  imageSrc={coach?.picture?.image?.asset?.url}
                  name={coach?.name}
                  phone={coach?.phone}
                  email={coach?.email}
                />
              </div>
              <div className='Coach-Details'>
                <h3>{coach?.name}</h3>
                <PortableText value={coach?._rawAbout} />
              </div>
            </div>
          ))}
        </div>
      </section>
    </Layout>
  );
};

export default CoachesPage;
